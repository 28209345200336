<template>
  <div>
    <div v-if="viewEntered">
      <div class="form-group-btn" v-if="!isB2cCustomer">
        <ion-button expand="block" @click="handleAddBranch" mode="md" color="primary"
          ><ion-icon :icon="addOutline" />{{ $t('newcustomer.add_brand') }}</ion-button
        >
      </div>
      <div class="card mb-2">
        <ion-card @click="handleOpenModal(customerDetails, false)">
          <CustomerDetailImage
            v-if="asyncData"
            :customer_name="customerDetails.name"
            :image="customerDetails.customerImage[0]?.image"
          />
          <ion-card-header>
            <ion-card-subtitle v-if="!isB2cCustomer">{{ $t('newcustomer.company_name') }}</ion-card-subtitle>
            <div class="title">
              <ion-card-title>{{ customerDetails.name }}</ion-card-title>
              <ion-img ref="testsss" v-if="customerDetails.halal_products" :src="halal"></ion-img>
            </div>
          </ion-card-header>
          <ion-card-content>
            <ion-card-subtitle>{{ $t('newcustomer.profile') }}</ion-card-subtitle>
            <div class="profile-text">{{ customerDetails.profile }}</div>
            <ion-card-subtitle>{{ $t('newcustomer.address') }}</ion-card-subtitle>
            <div class="profile-form" v-for="value in customerDetails.addresses" :key="value.id">
              <div class="profile-text" v-if="value.is_default">{{ getAddressFormat(value) }}</div>
            </div>
            <div class="d-flex flex-row pt-3">
              <div class="w-50">
                <ion-text class="ion-text-uppercase" color="text-medium"
                  ><p class="fs-12 fw-bold">{{ $t('customerPage.account_number') }}</p></ion-text
                >
                <ion-text class="profile-text" color="tertiary"
                  ><p>{{ customerDetails.account_number }}</p></ion-text
                >
              </div>
              <div class="w-50">
                <ion-text class="ion-text-uppercase fs-12 fw-bold" color="text-medium"
                  >{{ $t('credit_term') }}
                </ion-text>
                <ion-text class="profile-text" color="tertiary"
                  ><p>
                    {{ customerDetails.customer_credit_term.displayName }}
                  </p></ion-text
                >
              </div>
            </div>
            <div class="branch-form">
              <p v-if="!isB2cCustomer">{{ $t('newcustomer.main_brand') }}</p>
              <ion-chip v-else class="b2c-label-chip">B2C</ion-chip>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
      <div class="card mb-4" v-for="(data, index) in customerDetails.branches" :key="index">
        <ion-card v-if="customerDetails" @click="handleOpenModal(data, true)">
          <CustomerDetailImage
            v-if="asyncData"
            :customer_name="data.branch"
            :image="data.customerImage[0]?.image"
          />
          <ion-card-header>
            <ion-card-subtitle>{{ $t('newcustomer.company_name') }}</ion-card-subtitle>
            <div class="title">
              <ion-card-title>{{ data.branch }}</ion-card-title>
              <ion-img v-if="data.halal_products" :src="halal"></ion-img>
            </div>
          </ion-card-header>
          <ion-card-content>
            <ion-card-subtitle>{{ $t('newcustomer.profile') }}</ion-card-subtitle>
            <div class="profile-text">{{ data.profile }}</div>
            <ion-card-subtitle>{{ $t('newcustomer.address') }}</ion-card-subtitle>
            <div class="profile-form" v-for="value in data.addresses" :key="value.id">
              <div class="profile-text" v-if="value.is_default">{{ getAddressFormat(value) }}</div>
            </div>
            <div class="d-flex flex-row pt-3">
              <div class="w-50">
                <ion-text class="ion-text-uppercase" color="text-medium"
                  ><p class="fs-12 fw-bold">{{ $t('customerPage.account_number') }}</p></ion-text
                >
                <ion-text class="profile-text" color="tertiary"
                  ><p>{{ data.account_number }}</p></ion-text
                >
              </div>
              <div class="w-50">
                <ion-text class="ion-text-uppercase fs-12 fw-bold" color="text-medium">{{
                  $t('credit_term')
                }}</ion-text>
                <ion-text color="tertiary"
                  ><p>
                    {{ data.customer_credit_term.displayName }}
                  </p></ion-text
                >
              </div>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </div>
  <!-- modal -->
  <ion-modal mode="md" :is-open="isOpenRef" css-class="my-custom-class" @didDismiss="setOpen(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="setOpen(false)"
      />
      <ion-content>
        <ModalCustomerDetails
          @closeModal="setOpen(false)"
          @handleOpenModalBusiness="setOpenModalBusiness(true)"
          @handleOpenModalEditDeliveryTime="setOpenModalDeliveryTime(true)"
          @handleOpenModalUploadImage="setOpenModalUpdateImage(true)"
          @handleOpenModalPersonInCharge="setOpenModalPersonInCharge(true)"
          @handleOpenModalPayment="handleOpenModalPayment"
          @handleSendListShowPersonInCharge="handleSendListShowPersonInCharge"
          :mobilePhone="mobilePhone"
          :customerDetailsEdit="customerDetailsEdit"
          :isGetListCardWhenBackBtn="isGetListCardWhenBackBtn"
          :isBranch="isBranch"
          :countryId="countryId"
          @isKeyContact="handleFindKeyContact"
        />
      </ion-content>
    </ion-page>
  </ion-modal>
  <!-- Modal Upload Image -->
  <ion-modal mode="md" :is-open="isOpenModalUpdateImage" @didDismiss="setOpenModalUpdateImage(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="setOpenModalUpdateImage(false)"
      />
      <ion-content>
        <ModalUploadImage
          @handleCloseModalUploadImage="handleRenderComponent"
          @handleSendImageCrop="handleSendImageCrop"
          :image="customerDetailsEdit?.customerImage"
          :customer_id="customerDetailsEdit?.id"
          :imageAfterCrop="imageAfterCrop"
        />
      </ion-content>
    </ion-page>
  </ion-modal>
  <!-- Modal Business Information -->
  <ion-modal mode="md" :is-open="isOpenModalBusiness" @didDismiss="setOpenModalBusiness(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="setOpenModalBusiness(false)"
      />
      <ion-content :scroll-events="true" @ionScroll="handleScroll($event)">
        <ModalEditBusinessInfomation
          :customerDetailsEdit="customerDetailsEdit"
          @handle-rerender-component="handleRenderComponent"
        />
      </ion-content>
    </ion-page>
  </ion-modal>

  <ion-modal mode="md" :is-open="isOpenModalPersonInCharge" @didDismiss="setOpenModalPersonInCharge(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="setOpenModalPersonInCharge(false)"
      />
      <ion-content>
        <ModalPersonInCharge
          :isKeyContact="isKeyContact"
          :sortListShowPersonInCharge="customerDetailsEdit?.persons"
          :customerDetailsEdit="customerDetailsEdit"
          @handleReloadComponent="handleRenderComponent"
        />
      </ion-content>
    </ion-page>
  </ion-modal>
  <!-- Modal Delivery time -->
  <ion-modal mode="md" :is-open="isOpenModalDeliveryTime" @didDismiss="setOpenModalDeliveryTime(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="setOpenModalDeliveryTime(false)"
      />
      <ion-content>
        <ModalEditDeliveryTime
          :deliveryInstruction="customerDetailsEdit?.delivery_instruction"
          :workingHours="customerDetailsEdit?.working_hours"
          :defaultWorkingHours="defaultWorkingHours"
          @handleUpdateHour="handleUpdateHour"
        />
      </ion-content>
    </ion-page>
  </ion-modal>

  <!-- modal crop image -->
  <ion-modal :is-open="isOpenModalCrop" css-class="modal-upload-image" @didDismiss="setOpenModalCrop(false)">
    <crop-box
      :src="imageCrop"
      @close-modal="setOpenModalCrop(false)"
      @crop-image="handleSendImageAfterCrop"
    />
  </ion-modal>
  <ion-loading
    mode="ios"
    :is-open="isOpenRefLoading"
    cssClass="my-custom-class"
    :message="`${$t('please_wait')}. . .`"
  >
  </ion-loading>
</template>

<script>
import CropBox from '@/modules/shared/views/CropBox';

// import ModalAddress from './Modal/ModalAddress.vue';
import halal from '@/assets/images/b2b/home/halal.svg';
import { apolloClient } from '@/main';
import {
  getGroupsAndCountriesAndDays,
  hoursAdd,
  saleGetCustomerDetail
} from '@/modules/sale/services/graphql';
import { getAddressFormat } from '@/modules/sale/services/libs/helper';
import { getCurrentUser } from '@/services/shared/graphql';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { alertController, toastController } from '@ionic/vue';
import { addOutline } from 'ionicons/icons';
import { ref } from 'vue';
import CustomerDetailImage from './CustomerDetailImage.vue';
import Header from './HeaderModal.vue';
import ModalCustomerDetails from './Modal/ModalCustomerDetails.vue';
import ModalEditBusinessInfomation from './Modal/ModalEditBusinessInformation/index.vue';
import ModalEditDeliveryTime from './Modal/ModalEditDeliveryTime.vue';
import ModalPersonInCharge from './Modal/ModalPersonInCharge/index.vue';
import ModalUploadImage from './Modal/ModalUploadImage.vue';

export default {
  components: {
    CropBox,
    Header,
    CustomerDetailImage,
    ModalUploadImage,
    ModalCustomerDetails,
    ModalEditBusinessInfomation,
    ModalPersonInCharge,
    ModalEditDeliveryTime
  },
  emits: ['title'],
  inject: ['$storage'],
  setup() {
    const isOpenRef = ref(false);
    const setOpen = async (state) => (isOpenRef.value = state);
    const isOpenModalUpdateImage = ref(false);
    const setOpenModalUpdateImage = async (state) => (isOpenModalUpdateImage.value = state);
    const isOpenModalBusiness = ref(false);
    const setOpenModalBusiness = async (state) => (isOpenModalBusiness.value = state);
    const isOpenModalPersonInCharge = ref(false);
    const setOpenModalPersonInCharge = async (state) => (isOpenModalPersonInCharge.value = state);
    const isOpenModalAddAddress = ref(false);
    const setOpenModalAddAddress = async (state) => (isOpenModalAddAddress.value = state);
    const isOpenModalDeliveryTime = ref(false);
    const setOpenModalDeliveryTime = async (state) => (isOpenModalDeliveryTime.value = state);

    const isOpenRefLoading = ref(false);
    const setOpenLoading = async (state) => (isOpenRefLoading.value = state);
    const isOpenModalCrop = ref(false);
    const setOpenModalCrop = async (state) => (isOpenModalCrop.value = state);
    //modal cuisine
    const isOpenModalCuisine = ref(false);
    const setOpenModalCuisine = (state) => (isOpenModalCuisine.value = state);
    const handleScroll = (event) => {
      if (event && Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    };
    return {
      isOpenRef,
      setOpen,
      handleScroll,
      isOpenModalBusiness,
      setOpenModalBusiness,
      isOpenModalPersonInCharge,
      setOpenModalPersonInCharge,
      isOpenModalAddAddress,
      setOpenModalAddAddress,
      isOpenModalUpdateImage,
      setOpenModalUpdateImage,
      isOpenModalDeliveryTime,
      setOpenModalDeliveryTime,

      isOpenRefLoading,
      setOpenLoading,
      isOpenModalCrop,
      setOpenModalCrop,
      addOutline,
      halal,
      getAddressFormat,
      // ModalAddAddress
      isOpenModalCuisine,
      setOpenModalCuisine
    };
  },
  data() {
    return {
      viewEntered: false,
      customerId: Number(this.$route.params.id),
      customerDetails: [],
      asyncData: false,
      customerDetailsEdit: [],
      defaultWorkingHours: [],
      sortListShowPersonInCharge: [],
      isGetListCardWhenBackBtn: false,
      // check when user click modal branches
      idBranch: '',
      isBranch: false,
      //
      imageCrop: '',
      imageAfterCrop: '',
      mobilePhone: '',
      isKeyContact: false,
      countryId: null
    };
  },
  props: {
    detailsKey: {
      type: String,
      default: ''
    }
  },
  watch: {
    detailsKey: async function () {
      await this.handleGetCustomerDetailBuyer();
      await this.handleGetGroupsAndCountriesAndDays();
    }
  },
  computed: {
    isB2cCustomer() {
      return !!this.customerDetailsEdit?.is_b2c;
    }
  },
  async mounted() {
    const user = await this.$storage.getUser();
    this.countryId = user.country.id;
    await this.handleGetCustomerDetailBuyer();
    await this.handleGetGroupsAndCountriesAndDays();
    await this.handleGetDataUser();
    this.viewEntered = true;
  },

  methods: {
    handleFindKeyContact(event) {
      this.isKeyContact = event;
    },
    handleAddBranch() {
      this.$router.push({
        path: `/sale/customers/add`,
        query: {
          headId: this.customerId
        }
      });
    },
    handleSendListShowPersonInCharge(event) {
      this.sortListShowPersonInCharge = event;
    },
    async handleGetDataUser() {
      const { data } = await apolloClient.query({
        query: getCurrentUser
      });
      this.mobilePhone = data.me.mobile;
    },
    handleOpenModalPayment() {
      this.setOpenModalPayment(true);
    },
    handleGetListCardWhenBackPayment() {
      this.isGetListCardWhenBackBtn = !this.isGetListCardWhenBackBtn;
      this.setOpenModalPayment(false);
    },
    async handleUpdateHour(payload, delivery_instruction) {
      this.setOpenLoading(true);
      const customerId = this.customerDetailsEdit.id;
      payload = payload.map((item) => ({ ...item, customer_id: customerId }));
      const variables = {
        isDashboardPage: false,
        deliveryIntruction: delivery_instruction,
        tenantId: this.customerDetailsEdit.tenant_id,
        payload
      };
      try {
        await apolloClient.mutate({
          mutation: hoursAdd,
          variables: variables
        });
        this.setOpenLoading(false);
        await this.handleRenderComponent();
      } catch (error) {
        this.setOpenLoading(false);
        this.presentAlert(error);
      }
    },
    async handleRenderComponent(isUpdate) {
      await this.handleGetCustomerDetailBuyer();
      await this.openToast();
      await this.setOpenModalBusiness(isUpdate ? isUpdate : false);
      await this.setOpenModalDeliveryTime(false);
      await this.setOpenModalPersonInCharge(false);
      await this.setOpenModalUpdateImage(false);
    },
    async handleOpenModal(params, isBranch) {
      if (isBranch) {
        this.idBranch = params.id;
        this.isBranch = true;
      } else {
        this.isBranch = false;
        this.idBranch = false;
      }
      this.customerDetailsEdit = params;
      this.setOpen(true);
    },
    handleSendImageAfterCrop(event) {
      this.imageAfterCrop = event;
      this.setOpenModalCrop(false);
    },
    handleSendImageCrop(image) {
      this.imageCrop = image;
      this.setOpenModalCrop(true);
    },

    async handleGetCustomerDetailBuyer() {
      try {
        const { data } = await apolloClient.query({
          query: saleGetCustomerDetail,
          variables: {
            id: this.customerId
          }
        });
        this.customerDetails = data.saleGetCustomerDetail; //data show first
        if (this.isBranch) {
          this.customerDetailsEdit = data.saleGetCustomerDetail.branches.find(
            (item) => item.id === +this.idBranch
          );
        } else {
          this.customerDetailsEdit = data.saleGetCustomerDetail; //data bind props
        }

        this.$emit('title', data.saleGetCustomerDetail.name);
        this.asyncData = true;
      } catch (error) {
        this.presentAlert(error);
      }
    },
    async handleGetGroupsAndCountriesAndDays() {
      const { data } = await apolloClient.query({
        query: getGroupsAndCountriesAndDays,
        variables: {}
      });
      this.defaultWorkingHours = data.getGroupsAndCountriesAndDays.days;
    },
    async presentAlert(message) {
      this.setOpenLoading(false);
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: 'Alert',
        message: message,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    },
    async openToast() {
      const toast = await toastController.create({
        mode: 'ios',
        color: 'dark',
        position: 'top',
        message: this.$t('saved_successfully'),
        cssClass: 'toast-custom-class',
        duration: 1000
      });
      return toast.present();
    }
  }
};
</script>

<style src="./styles/CustomerDetail.scss" lang="scss" scoped></style>
